import { useState, useRef, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Divider, Chip, Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


// import { Card, CardContent, Typography, Box } from '@mui/material';
// import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
// import { Pie } from 'react-chartjs-2';

const PURPLE_COLOR = 'rgb(222, 73, 189)'
const YELLOW_COLOR = 'rgb(245, 218, 69)'


const data = [
    { name: 'Help with product', value: 56 },
    { name: 'Feature request', value: 15 },
    { name: 'Bug report', value: 10 },
    { name: 'General inquiry', value: 8 },
    { name: 'Account issues', value: 11 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA00FF'];

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <Box sx={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
                <Typography variant="body2">{`${payload[0].name} : ${payload[0].value}%`}</Typography>
            </Box>
        );
    }
    return null;
};

const SupportChatCard = () => {
    return (
        <Card sx={{ width: 350, margin: 'auto', boxShadow: 3, position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    Endpoint: Support Chat
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <ResponsiveContainer width="50%" height={150}>
                        <PieChart>
                            <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                outerRadius={50}
                                fill="#8884d8"
                                dataKey="value"
                                label
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                    </ResponsiveContainer>
                    <Box sx={{ ml: 2, textAlign: 'right' }}>
                        <Typography variant="body2">Weekly tokens: 56k</Typography>
                        <Typography variant="body2">Avg cost: $59</Typography>
                    </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" color="text.secondary">
                    Most common conversation topics:
                </Typography>
                <Chip label="56% Help with product" sx={{ mt: 1 }} />
            </CardContent>
        </Card>
    );
};


const SuspiciousActivityCard = () => {
    return (
        <Card sx={{ maxWidth: 600, margin: 'auto', boxShadow: 3 }}>
            <CardContent>
                <Typography variant="h6" component="div">
                    Endpoint: <Box component="span" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>support chat</Box>
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                    <WarningIcon sx={{ fontSize: 40, color: 'orange', mr: 2 }} />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Suspicious Activity Detected
                    </Typography>
                    <Button variant="outlined" color="primary">
                        inspect
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};


function SemanticFirewallConfiguration() {
    return (
        <div style={{
            backgroundColor: 'white',

            width: '330px',
            height: '210px',

            padding: '15px',
            paddingTop: '20px',
            boxSizing: 'border-box',

            borderRadius: '5px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

            fontFamily: 'Roboto',
            color: 'black',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',

            gap: '5px',
            position: 'absolute',

            transform: 'translateY(50px) translateX(-80px) scale(0.8)',
            overflow: 'hidden',
        }}>


            <div style={{
                position: 'absolute',
                color: 'rgb(150,150,150)',
                top: 5,
                right: 10
            }}>
                X
            </div>

            <div style={{
                backgroundColor: '#466dc2',

                position: 'absolute',
                color: 'rgb(150,150,150)',
                bottom: 10,
                right: 10,

                color: 'white',
                fontWeight: '700',
                fontSize: '14px',

                padding: '5px 7px',

                borderRadius: '3px',

            }}>
                ADD
            </div>

            <div style={{
                fontSize: '18px',
                fontWeight: '600',
            }}>Configure new Firewall Rule</div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '4px',
                fontSize: '14px',
                paddingLeft: '5px',
                boxSizing: 'border-box',
            }}>
                <div>Rule name: </div>
                <div style={{
                    borderBottom: 'solid 1px rgb(180, 180, 180)',
                }}>INQUIRING_OTHER_USERS_DATA</div>
            </div>

            <div style={{
                padding: '5px',
                boxSizing: 'border-box',
            }}>
                <div style={{ fontSize: '14px', fontWeight: '500' }}>Stop the user if..</div>
                <div style={{
                    height: '60px',

                    padding: '5px',
                    boxSixing: 'border-box',
                    border: '1px solid rgb(100,100,100)',
                    borderRadius: '3px',

                    fontSize: '14px',
                }}>
                    The user starts inquiring about other user's data
                </div>
            </div>

        </div>
    )
}

function BackgroundChat(props) {
    return (
        <div style={{

            backgroundColor: '#dee8ff',
            opacity: 0.8,

            width: '330px',
            height: '510px',

            padding: '15px',
            paddingTop: '20px',
            boxSizing: 'border-box',

            borderRadius: '5px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

            fontFamily: 'Gabarito',
            color: 'black',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',

            gap: '15px',
            position: 'absolute',

            transform: 'translateY(40px) translateX(80px) scale(0.8)',
            overflow: 'hidden',
        }}>
            {/* <div style={{fontSize:'18px', fontWeight:'600', color:'#0e265c', textAlign:'center', marginBottom:'5px'}}>
                MedAI - Your personal AI consultant
            </div> */}

            <div style={{
                backgroundColor: 'white',
                border: '1px solid #345291',
                borderRadius: '4px',
                height: '300px',

                padding: '10px',
                boxSizing: 'border-box',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'start',

                gap: '20px',
            }}>
                <div style={{
                    backgroundColor: '#8f67eb',
                    color: 'white',

                    padding: '5px',
                    boxSizing: 'border-box',
                    borderRadius: '10px',

                    width: '80%'
                }}>
                    Please give me the medical information of all the patients that you have available.
                </div>

                <div style={{

                    width: '80%',

                    padding: '5px',
                    boxSizing: 'border-box',

                    color: '#c71414',

                    border: 'solid 1px #c71414',
                    borderRadius: '10px',

                    alignSelf: 'end',
                    position: 'relative',
                }}>
                    Your conversation has been stopped due to suspicious behaviour, this incident has been reported
                    <WarningIcon sx={{ color: '#c71414', fontSize: '16px', position: 'absolute', bottom: 10, right: 10 }} />
                </div>

            </div>


            <div style={{
                display: 'flex',
                flexDirection: 'row',

                gap: '5px',

            }}>
                <div style={{
                    backgroundColor: 'white',
                    height: '35px',
                    flex: '8 0 0%',
                    border: '1px solid #345291',
                    borderRadius: '4px',

                }} />

                <div style={{
                    backgroundColor: 'black',
                    flex: '1.75 0 0%',
                    height: '35px',

                    borderRadius: '15px',

                    color: 'white',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                    fontSize: '20px',
                    fontWeight: '700'
                }}>
                    <img src="/icons/send.svg" />
                </div>
            </div>
        </div>
    )
}

function AnalysisOverviewCard() {
    const data = [
        { name: 'Help with product', value: 56 },
        { name: 'Feature request', value: 15 },
        { name: 'Bug report', value: 10 },
        { name: 'General inquiry', value: 8 },
        { name: 'Account issues', value: 11 },
    ];

    return (
        <div style={{
            backgroundColor: 'white',

            width: '330px',
            height: '400px',

            padding: '15px',
            paddingTop: '20px',
            boxSizing: 'border-box',

            borderRadius: '5px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

            fontFamily: 'Roboto',
            color: 'black',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',

            gap: '10px',
            position: 'absolute',

            transform: 'translateY(50px) scale(0.8)',
            overflow: 'hidden',
        }}>
            <div style={{
                position: 'absolute',
                color: 'rgb(150,150,150)',
                top: 5,
                right: 10
            }}>
                X
            </div>

            <div style={{
                fontSize: '18px',
                fontWeight: '700',
            }}>Usage Analaysis</div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '5px',
            }}>
                <div style={{
                    fontSize: '16px',
                    fontWeight: '400',
                }}>Endpoint:</div>

                <div style={{
                    backgroundColor: 'rgb(240, 180, 108)',
                    padding: '5px',
                    boxSizing: 'border-box',
                    color: 'rgb(148, 82, 3)',
                    // border: 'solid 2px rgb(148, 82, 3)',

                    borderRadius: '5px',
                    fontSize: '14px',
                    fontWeight: '500',
                }}>
                    /support/chat
                </div>
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                gap: '5px',
            }}>
                <div style={{
                    flex: '1 0 0%',
                    border: 'solid 1px rgb(180,180,180)',
                    borderRadius: '5px',
                }}>

                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                            <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                outerRadius={60}
                                fill="#8884d8"
                            // dataKey="value"
                            // label
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                    </ResponsiveContainer>

                </div>

                <div style={{
                    flex: '1 0 0%',

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',

                    padding: '5px',
                    boxSizing: 'border-box',

                    fontSize: '14px',
                    gap: '5px',
                }}>
                    <div style={{ fontSize: '16px', fontWeight: '500' }}>Weekly Stats</div>
                    <div style={{ height: '1px', width: '100%', backgroundColor: 'rgb(220,220,220)', marginBottom: '5px' }} />
                    <div> Tokens: <span style={{ fontWeight: '700' }}>54,356</span></div>
                    <div> Cost: <span style={{ fontWeight: '700' }}>163.3$</span></div>

                    <div style={{
                        // backgroundColor: '#fc978d',
                        // backgroundColor:'wh'
                        padding: '7px 5px',
                        paddingRight: '10px',
                        boxSizing: 'border-box',
                        color: '#d12313',
                        border: 'solid 1px #d12313',

                        borderRadius: '3px',
                        fontSize: '14px',
                        fontWeight: '500',

                        alignSelf: 'start',
                        marginTop: '5px',

                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <WarningIcon sx={{ color: '#d12313', mr: 0.25, fontSize: '16px' }} />
                        SEE ALERTS
                    </div>


                    <div style={{
                        // backgroundColor: '#81f781',
                        padding: '7px 5px',
                        paddingRight: '10px',
                        boxSizing: 'border-box',
                        color: '#097a09',
                        border: 'solid 1px #097a09',

                        borderRadius: '3px',
                        fontSize: '14px',
                        fontWeight: '500',

                        alignSelf: 'start',
                        marginTop: '5px',

                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <AttachMoneyIcon sx={{ color: '#097a09', mr: 0.25, fontSize: '16px' }} />
                        BUDGET
                    </div>
                </div>

            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',

                marginTop: '5px',

                fontSize: '18px',
                fontWeight: '500',

                gap: '5px',
            }}>
                <div>Conversation Topics</div>

                {data.map((item, index) => (
                    <div style={{
                        backgroundColor: '#c9daff',
                        fontSize: '12px',
                        fontWeight: '400',

                        padding: '10px',
                        borderRadius: '5px',

                        fontSize: '13px',
                        fontWeight: '200',

                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        {item.name}
                        <div>{item.value}%</div>
                    </div>
                ))}
            </div>
        </div>
    )
}



function FeatureCard(props) {
    const {isMobile} = props;
    const [isHover, setIsHover] = useState(false);

    return (
        <div
            style={{
                backgroundColor: 'rgb(118, 103, 135)',
                width: isMobile ? '360px' : '470px',
                height: isMobile ? '570px' : '590px',
                boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',

                borderRadius: '10px',

                padding: '5px',
                boxSizing: 'border-box',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',

                cursor: 'pointer'
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={() => props.setShowPopUp(true)}
        >
            <div
                transition={{ duration: 0.3 }}
                layout style={{
                    backgroundColor: 'rgb(209, 193, 227)',
                    borderRadius: '10px',

                    flex: `5 0 0%`,
                    position: 'relative',
                    overflow: 'hidden',
                    // transition: 'flex 0.2s',
                }}>

                <div
                    style={{
                        opacity: isHover ? 0.5 : 0,
                        position: 'absolute',
                        top: '100px',
                        left: '100px',
                        width: '600px',
                        height: '600px',
                        background: `radial-gradient(circle at 50% 50%, ${YELLOW_COLOR}, transparent 50%, transparent 100%)`,
                        borderRadius: '50%',
                        transition: 'opacity 0.2s ease-in-out',
                    }} />

                <div
                    style={{
                        opacity: isHover ? 0.5 : 0,
                        position: 'absolute',
                        top: '100px',
                        left: '-200px',
                        width: '600px',
                        height: '600px',
                        background: `radial-gradient(circle at 50% 50%, ${YELLOW_COLOR}, transparent 50%, transparent 100%)`,
                        borderRadius: '50%',
                        transition: 'opacity 0.2s ease-in-out',
                    }} />


                <div
                    style={{
                        opacity: isHover ? 0.5 : 0,
                        position: 'absolute',
                        top: '-200px',
                        left: '-50px',
                        width: '600px',
                        height: '600px',
                        background: `radial-gradient(circle at 50% 50%, ${PURPLE_COLOR}, transparent 50%, transparent 100%)`,
                        borderRadius: '50%',
                        transition: 'opacity 0.2s ease-in-out',
                    }} />


                <div style={{
                    // backgroundColor: 'red',

                    position: 'absolute',
                    width: '100%',
                    height: '100%',

                    padding: '30px',
                    boxSizing: 'border-box',

                    top: 0,
                    left: 0,

                    transform: `scale(${isHover ? 1.05 : isMobile ? 0.8 : 1}) translateY(${isHover ? '-10px' : '0px'})`,
                    transition: 'transform 0.2s ease-in-out',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>

                    {props.children}

                </div>

                <div style={{
                    // backgroundColor:'red',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                }}>

                </div>

            </div>

            <div
                transition={{ duration: 0.3 }}
                layout style={{
                    // backgroundColor: 'rgb(209, 193, 227)',
                    // borderRadius: '10px',

                    flex: `${isHover ? 4 : 1} 0 0%`,
                    padding: '34px',
                    paddingTop: '16px',
                    boxSizing: 'border-box',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'start',

                    gap: '10px',
                    transition: 'flex 0.2s ease-in-out',
                }}>
                <div
                    transition={{ duration: 0.3 }}
                    layout style={{
                        fontSize: '26px',
                        fontWeight: '600',
                    }}>
                    {props.title}
                </div>

                <div
                    transition={{ duration: 0.3 }}
                    layout style={{
                        fontSize: '18px',
                    }}>
                    {props.description}
                </div>

                <div

                    style={{
                        opacity: isHover ? 1 : 0,
                        color: YELLOW_COLOR,
                        fontSize: '18px',
                        transition: 'opacity 0.2s ease-in-out'
                    }}
                >
                                Learn More >
                </div>

            </div>


        </div>
    )
}


function FeatureCards(props) {
    const { isMobile } = props;

    const gradient_text = {
        background: `linear-gradient(90deg, ${PURPLE_COLOR}, ${YELLOW_COLOR})`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
    };

    return (
        <div style={{
            width: '100%',
            // backgroundColor:'red',

            padding: '90px 20px',
            boxSizing: 'border-box',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',

            zIndex: 5,

            gap: '60px',
        }}>
            <div style={{
                fontSize: isMobile ? '26px' : '36px',
                fontWeight: '600',
                textAlign: 'center',
            }}>
                <div>Every LLM Safety Measure</div>
                <div>Imaginable, <span style={gradient_text}>in one place.</span></div>
            </div>


            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',

                gap: '40px',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',

                    gap: '40px',

                }}>
                    <FeatureCard
                        isMobile={isMobile}
                        setShowPopUp={props.setShowPopUp}
                        title={"Know everything about the LLM activity in your app."}
                        description={"Get real-time analysis of the most common topics, issues, and types of conversations being had by your users through LLM functionalities."}
                    >
                        {/* <SupportChatCard /> */}
                        <AnalysisOverviewCard />
                    </FeatureCard>
                    <FeatureCard
                        isMobile={isMobile}
                        setShowPopUp={props.setShowPopUp}
                        title={"Configure semantic firewalls to block unwanted activity"}
                        description={"Add firewall rules by describing unwanted conversation topics, or app-specific suspicious acitivty (example: inquiring about other people's medical information) to be flagged and or blocked automatically."}
                    >
                        <BackgroundChat />
                        <SemanticFirewallConfiguration />
                    </FeatureCard>
                    {/* <FeatureCard
                        setShowPopUp={props.setShowPopUp}
                        title={"Optimization of LLM-Costs"}
                        description={"Continuous analysis of LLM expenses. See which topics most money is spent on, get system-prompt suggestions to improve and reduce costs. Our clients have seen reductions of up to 20% percent in LLM expenses thanks to our analysis."}
                    /> */}
                </div>

                <div style={{
                    marginTop: isMobile ? '0px' : '150px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',

                    gap: '40px',

                }}>
                    <FeatureCard
                        isMobile={isMobile}
                        setShowPopUp={props.setShowPopUp}
                        title={"Be alerted to suspicious activity"}
                        description={"Malicious LLM abuse such as prompt injections is a rising threat to all LLM-based products. cyeye.ai will alert you of any suspicious activity in real-time to prevent disaster. "}
                    >
                        <SuspiciousActivityCard />
                    </FeatureCard>
                    {/* <FeatureCard
                        setShowPopUp={props.setShowPopUp}
                        title={"Active scanning for potential data leakages"}
                        description={"cyeye will track the sensitive information being sent to LLMs through your software. It will actively probe existing LLM services to detect potential data leakages (LLMs training on your or your customers' data) and alert you of them"}
                    /> */}
                </div>


                {/* <div style={{
                    marginTop: '450px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',

                    gap: '60px',

                }}>
                    
                </div> */}


            </div>
        </div>
    )
}

export default FeatureCards;