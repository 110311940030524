import { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import HomePage2 from './homepage2';

import logo from './logo.svg';
import './App.css';

const email_listener_url = "https://l1z6immock.execute-api.eu-west-1.amazonaws.com/dev/notify";

function Logo(props) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',

      fontSize: '70px',
      fontWeight: '500',
    }}>
      <img src="/wyw.svg" style={{
        // backgroundColor: 'red',
        height: '70px',
        padding: '5px',
        boxSizing: 'border-box',

        position: 'relative',
        bottom: -6
      }} />

      <span style={{
        // backgroundColor:'blue'
      }}>cyeye.ai</span>
    </div>
  );
}

function CatchPhrase() {
  return (
    <div style={{
      textAlign: 'center'
    }}>
      <div style={{
        color: 'rgb(200,200,200)',
        fontSize: '40px',
        fontWeight: 50,
      }}>
        Scared of Integrating LLM-Tech into Your Product?
      </div>


      <div style={{
        color: 'rgb(200,200,200)',
        fontSize: '30px',
        fontWeight: 50,
      }}>
        You are one code-line away from safely deploying AI based products.
      </div>
    </div>
  )
}


function CodeArea(props) {

  const bottom_lines_with_eyeai = [
    "Active monitoring of all activity",
    "Filtering and alerts based on the data users request and see",
    "Smart AI-based testing to pre-detect potential data leakage and security vulnerabilites"
  ];

  const bottom_lines_without_eyeai = [
    "No monitoring",
    "No filtering",
    "No testing"
  ];

  const line_of_code_style = { margin: '0px', marginBottom: '5px', padding: '0px' }
  const error_code_style = { color: 'rgb(255,100,100)', backgroundColor: 'rgba(255, 50, 50, 0.5)' }
  const eyeai_code_style = { color: 'rgb(100,255,100)', backgroundColor: 'rgba(50, 255, 50, 0.5)' }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',

      zIndex: 2,
    }}>
      <div style={{
        fontSize: '25px',
        fontWeight: 'bold',
        padding: '5px',
        boxSizing: 'border-box'
      }}>{props.eyeai ? "With cyeye.ai" : "Without cyeye.ai"}</div>

      <div style={{
        backgroundColor: 'rgb(15, 18, 15)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',

        minWidth: 1,
        minHeight: 1,

        margin: '10px',
        fontFamily: 'Roboto Mono',
        fontSize: '20px',
      }}>
        <div style={{
          backgroundColor: '#2F2F2F',
          width: '40px',

          textAlign: 'end',
          padding: '10px',
          boxSizing: 'border-box',
        }}>
          {Array.from({ length: 7 }, (_, index) => (
            <pre key={index} style={line_of_code_style}>{index}</pre>
          ))}

        </div>
        <div style={{

          padding: '10px',
          boxSizing: 'border-box',

          width: (props.isMobile ? '300px' : '500px'),

          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'start',

          overflowX: 'auto'
        }}>
          {
            props.eyeai ?
              <>
                <pre style={line_of_code_style}><span style={{ color: '#2e95d3' }}>from </span><span style={eyeai_code_style}>cyeyeai</span><span style={{ color: '#2e95d3' }}> import</span> openai</pre>

                <pre style={line_of_code_style}>{" "}</pre>
                <pre style={line_of_code_style}>response = <span style={{ backgroundColor: 'rgba(0,255,0, 0.3)' }}>openai.Completion.create(</span></pre>
                <pre style={line_of_code_style}>{'\t'}<span style={{ backgroundColor: 'rgba(0,255,0, 0.3)' }}>engine=<span style={{ color: '#00a67d' }}>"gpt-4-turbo"</span>,</span></pre>
                <pre style={{ ...line_of_code_style, position: 'relative' }}>{`\t`}<span style={{ backgroundColor: 'rgba(0,255,0, 0.3)' }}>prompt=precious_company_data,</span><div style={{
                  // backgroundColor: 'red',
                  position: 'absolute',
                  height: '100%',
                  // width:'25px',
                  top: 2,
                  right: '-30px',
                }}>
                  <img src="/wyw.svg" style={{ width: '100%', height: '100%' }} />
                </div></pre>
                <pre style={{ ...line_of_code_style, backgroundColor: 'rgba(0,255,0, 0.3)' }}>{`)`}</pre>
              </>
              :
              <>
                <pre style={line_of_code_style}><span style={{ color: '#2e95d3' }}>import</span> openai</pre>

                <pre style={line_of_code_style}>{" "}</pre>
                <pre style={line_of_code_style}>response = openai.Completion.create(</pre>
                <pre style={line_of_code_style}>{`\tengine=`}<span style={{ color: '#00a67d' }}>"gpt-4-turbo"</span>,</pre>
                <pre style={line_of_code_style}>{`\tprompt=`}<span style={error_code_style}>precious_company_data</span>,</pre>
                <pre style={line_of_code_style}>{`)`}</pre>
              </>
          }


        </div>
      </div>

      <div>
        {
          (props.eyeai ? bottom_lines_with_eyeai : bottom_lines_without_eyeai).map((item, index) => (
            <div key={index} style={{
              fontSize: '20px',
              maxWidth: '500px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'stretch',
              padding: '5px',
              boxSizing: 'border-box',

              fontSize: '20px',
              fontWeight: 'bold',
              color: 'rgb(200,200,200)'
            }}>
              {props.eyeai ? "✔️" : "❌"}
              <div>{item}</div>
            </div>
          ))
        }
      </div>

    </div>
  )
}

function CodeComparison(props) {
  const isMobile = props.isMobile;


  return (
    <div style={{
      // background: 'linear-gradient(to bottom, black, rgb(46, 37, 56))',
      width: '100%',
      maxWidth: '100vw',

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'start',
      flexWrap: 'wrap',

      padding: '60px',
      boxSizing: 'border-box',
      overflowX: 'hidden',

      position: 'relative',

      zIndex: 3,
    }}>
      <div style={{
        background: 'linear-gradient(to bottom, black, transparent)',
        width: '100%',
        height: '40%',

        position: 'absolute',
        top: 0,
        left: 0,

        zIndex: 1,
      }}>

      </div>

      <CodeArea isMobile={isMobile} />
      {!props.isMobile ?
        (<img src="/arrow.svg" style={{ width: '40px', alignSelf: 'center', position: 'relative', bottom: 50 }} />) :
        (<div style={{ width: '20px', height: '50px' }}></div>)}
      <CodeArea eyeai={true} isMobile={isMobile} />
    </div>
  )
}

function InputField(props) {
  const { value, setValue } = props;
  const [isActive, setIsActive] = useState(false);
  // const [renderRequired]

  return (
    <div>

      {
        props.showReq && (
          <div style={{
            color: 'red',
            fontSize: '15px',
          }}>* Required field</div>
        )
      }

      <div style={{

        backgroundColor: 'rgba(0,0,0,0.5)',

        width: '500px',
        maxWidth: '90vw',
        height: '60px',

        padding: '2px',
        paddingLeft: '15px',
        boxSizing: 'border-box',

        margin: '10px',

        border: `5px solid ${isActive || value.length ? "rgb(207, 41, 138)" : "rgb(99, 30, 71)"}`,
        borderRadius: '30px',

        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        alignItems: 'center',

        position: 'relative',

        transition: '0.3s all'
      }}>

        <textarea style={{
          backgroundColor: 'rgb(0,0,0,0)',
          resize: 'none',
          border: 'none',
          width: '100%',
          height: '80%',
          // height: '100%',

          color: 'white',
          fontFamily: 'Gabarito',
          fontSize: '30px',

          overflow: 'hidden',
          outline: 'none',

          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />

        {
          !value.length && (
            <div style={{
              // width: '100%',
              height: '80%',
              color: 'gray',
              fontFamily: 'Gabarito',
              fontSize: '30px',

              position: 'absolute',
              left: '15px',

              pointerEvents: 'none'

            }}>
              {props.defaultValue}
            </div>
          )
        }

      </div>
    </div>
  )
}

function Button(props) {
  const [hover, setHover] = useState(false);

  return (

    <div style={{
      backgroundColor: hover && props.active ? 'gray' : 'black',
      opacity: props.active ? 1 : 0.5,
      margin: '20px',
      padding: '10px 20px 10px 20px',
      boxSizing: 'border-box',

      fontSize: '25px',

      borderRadius: '20px',

      cursor: props.active ? 'pointer' : 'default',

      transform: `scale(${hover && props.active ? 1.1 : 1})`,
      transition: '0.3s all',

      ...props.style,
    }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={props.active ? props.onClick : () => null}
    >
      {props.children}
    </div>
  );
}

function ContactUs() {
  const [name, setName] = useState("");
  const [nameReq, setNameReq] = useState(false);

  const [email, setEmail] = useState("");
  const [emailReq, setEmailReq] = useState(false);

  const [buttonActive, setButtonActive] = useState(true);

  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  async function SignUp() {

    if (!name.length) {
      setNameReq(true);
    }

    if (!email.length) {
      setEmailReq(true);
    }

    if (!email.length || !name.length)
      return;

    setButtonActive(false);

    const response = await fetch(email_listener_url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: name,
        email: email
      })
    })


    setButtonActive(true);

    setNameReq(false);
    setEmailReq(false);

    setName("");
    setEmail("");

    setSuccess(true);

  }

  return (
    <div style={{
      // backgroundColor: 'rgb(46, 37, 56)',
      height: '100vh',
      width: '100%',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      padding: '40px',
      paddingBottom: '150px',
      boxSizing: 'border-box',

      zIndex: 2,
    }}>
      <div style={{
        fontSize: '50px',
        fontWeight: 'bold'
      }}>Contact Us</div>

      <div style={{
        fontSize: '35px',
        color: 'rgb(200,200,200)',
        marginBottom: '30px',

        textAlign: 'center',
      }}>
        Learn how you can use cyeye.ai in your product
      </div>

      {/* <div style={{
        fontSize: '35px',
        color: 'rgb(255,255,255)'
      }}>
        cyeyeai.contact@gmail.com
      </div> */}

      <InputField value={name} setValue={setName} showReq={nameReq} defaultValue="Name" />
      <InputField value={email} setValue={setEmail} showReq={emailReq} defaultValue="Email" />

      <Button onClick={SignUp} active={buttonActive}>Submit</Button>
      {
        success &&
        <div>
          Thanks for reaching out! We will get back to you asap.
        </div>
      }

      <div style={{
        margin: '5px',
        fontSize: '20px',
        cursor: 'pointer'
      }}
        onClick={() => navigate('/terms-of-service')}
      >Terms of Service</div>

      <div style={{
        margin: '5px',
        marginTop: '15px',
        fontSize: '20px',
        cursor: 'pointer'
      }}
        onClick={() => navigate('/privacy-policy')}
      >Privacy Policy</div>
    </div>
  )
}

function FeatureBody(props) {
  const { title, description, isMobile } = props;

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 0%',

      paddingRight: isMobile ? 'none' : '20px',
      paddingLeft: isMobile ? 'none' : '20px',
      boxSizing: 'border-box'
    }}>
      <div style={{
        fontSize: isMobile ? '40px' : '50px',
        fontWeight: 'bold',
      }}>
        {title}
      </div>

      <div style={{
        fontSize: isMobile ? '20px' : '30px',
      }}>
        {description}
      </div>

      {
        !isMobile && (
          <Button active={true} style={{ alignSelf: 'center' }}
            onClick={() => {
              window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
              })


              fetch(email_listener_url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  action_type: 'someone clicked learn more',
                  section: title
                })
              })

            }}
          >Learn more</Button>
        )
      }

    </div>
  )
}


function FeatureImage(props) {
  const { img_url, isMobile } = props;

  return (
    <>
      <div style={{
        backgroundColor: 'black',
        borderRadius: '20px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        flex: '1 0 0%',
        overflow: 'hidden',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        <img src={img_url} style={{
          width: '100%',
        }} />
      </div>


      {
        isMobile && (
          <Button active={true} style={{ alignSelf: 'center' }}
            onClick={() => {
              window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
              })


              fetch(email_listener_url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  action_type: 'someone clicked learn more',
                  section: props.title
                })
              })

            }}
          >Learn more</Button>
        )
      }


    </>
  )
}


function FeatureBlock(props) {
  const { title, description, img_url, reversed, isMobile } = props;
  return (
    <div style={{
      // backgroundColor: 'green',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',

      padding: isMobile ? '5px' : '50px',
      boxSizing: 'border-box',

      width: '80%',
      marginBottom: isMobile ? '100px' : 'none',
    }}>

      <div style={{
        display: 'flex',
        flexDirection: (isMobile ? 'column' : 'row'),
        justifyContent: 'flex-start',
        alignItems: 'stretch',

      }}>
        {
          (reversed && !isMobile) ? (
            <>
              <FeatureImage img_url={img_url} isMobile={isMobile} />
              <FeatureBody title={title} description={description} isMobile={isMobile} />
            </>
          ) :
            (
              <>
                <FeatureBody title={title} description={description} isMobile={isMobile} />
                <FeatureImage title={title} img_url={img_url} isMobile={isMobile} />
              </>
            )
        }

      </div>



    </div>
  )
}

function Features(props) {
  const { isMobile } = props;

  return (
    <div style={{
      // backgroundColor: 'rgb(46, 37, 56)',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',

      zIndex: 2,
    }}>
      <div style={{
        maxWidth: '1500px',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}>
        <FeatureBlock title={"All your LLM activity in one dashboard."} isMobile={isMobile}
          description={<pre style={{
            fontFamily: 'Gabarito',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            // marginBottom: '0px',
          }}>
            {
              `With one line of code, all the LLM traffic going in and out of your servers is analyzed, monitored and summarized in an easy-to-read dashboard.

See what users are talking about, what are most resources spent on, etc.`
            }
          </pre>} img_url={"/features/capture2.PNG"} />


        <FeatureBlock title={"Continous data-leakage monitoring"} isMobile={isMobile}
          description={<pre style={{
            fontFamily: 'Gabarito',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            // marginBottom: '0px',
          }}>
            {
              `Your product sends sensitive customer data to external AI services. 
Are you sure it's safe?

cyeye.ai continuously monitors and tests external LLM services for data leakages by probing AI responses for any unauthorized dissemination of sensitive company information, ensuring data security and privacy.
`
            }
          </pre>} img_url={"/features/capture1.PNG"} reversed={true} />

      </div>
    </div>
  )
}



export const MovingDots = () => {
  const canvasRef = useRef(null);
  const parentRef = useRef(null);
  const dots = useRef([]);
  const animationFrameId = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const parent = parentRef.current;
    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      canvas.width = parent.clientWidth;
      canvas.height = parent.clientHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const numDots = 1000;
    const maxDistance = 100;
    const width = canvas.width;
    const height = canvas.height;

    // Initialize dots
    for (let i = 0; i < numDots; i++) {
      dots.current.push({
        x: Math.random() * width,
        y: Math.random() * height,
        vx: (Math.random() - 0.5) * 0.5,
        vy: (Math.random() - 0.5) * 0.5
      });
    }

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Update dot positions
      dots.current.forEach(dot => {
        dot.x += dot.vx;
        dot.y += dot.vy;

        if (dot.x < 0 || dot.x > canvas.width) dot.vx *= -1;
        if (dot.y < 0 || dot.y > canvas.height) dot.vy *= -1;
      });

      // Draw dots
      dots.current.forEach(dot => {
        ctx.beginPath();
        ctx.arc(dot.x, dot.y, 3, 0, Math.PI * 2);
        ctx.fillStyle = 'white';
        ctx.fill();
      });

      // Draw lines between close dots
      for (let i = 0; i < numDots; i++) {
        for (let j = i + 1; j < numDots; j++) {
          const dx = dots.current[i].x - dots.current[j].x;
          const dy = dots.current[i].y - dots.current[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < maxDistance) {
            ctx.beginPath();
            ctx.moveTo(dots.current[i].x, dots.current[i].y);
            ctx.lineTo(dots.current[j].x, dots.current[j].y);
            ctx.strokeStyle = `rgba(255, 255, 255, ${1 - distance / maxDistance})`;
            ctx.stroke();
          }
        }
      }

      animationFrameId.current = requestAnimationFrame(draw);
    };

    draw();

    // Cleanup on component unmount
    return () => {
      dots.current = [];
      window.removeEventListener('resize', resizeCanvas);
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  return (
    <div ref={parentRef} style={{ width: '100%', height: '100%', position: 'relative' }}>
      <canvas id="moving_dots_canvas" ref={canvasRef} style={{ display: 'block', position: 'absolute', top: 0, left: 0, zIndex: 1, opacity: 0.5 }} />
    </div>
  );
};

function TestElement() {
  return (
    <>
      <div style={{
        backgroundColor: 'black',
        width: '400px',
        height: '400px',

        borderRadius: '5%',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',

        zIndex: 2,

        position: 'relative',
      }}>

        <div style={{

          width: '100%',

          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',

          fontSize: '50px',
          fontWeight: '500',

          padding: '5px',
          boxSizing: 'border-box',

          // position:'absolute',
          // left:0,
          // top:0,
        }}>
          <img src="/wyw.svg" style={{
            // backgroundColor: 'red',
            height: '50px',
            padding: '5px',
            boxSizing: 'border-box',

            position: 'relative',
            bottom: -6
          }} />

          <span style={{
            // backgroundColor:'blue'
          }}>cyeye.ai</span>
        </div>

        <div style={{
          backgroundColor: 'blue',
          padding: '5px',
          boxSizing: 'border-box'
        }}>

        </div>


      </div>
      {/* <svg style={{zIndex:2}}
      width="1200" height="600" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="100%" fill="#FDECE4" />

        <g transform="translate(50,50)">
          <rect x="0" y="0" width="300" height="200" rx="10" ry="10" fill="#FFE0CF" />
          <text x="50%" y="-20" text-anchor="middle" font-family="Arial" font-size="18" fill="#000">Permit Policy Editor</text>

          <text x="70" y="20" font-family="Arial" font-size="16" fill="#000">Document</text>
          <text x="120" y="20" font-family="Arial" font-size="16" fill="#000">Admin</text>
          <text x="170" y="20" font-family="Arial" font-size="16" fill="#000">Editor</text>
          <text x="220" y="20" font-family="Arial" font-size="16" fill="#000">Viewer</text>

          <text x="10" y="60" font-family="Arial" font-size="16" fill="#000">View</text>
          <text x="10" y="90" font-family="Arial" font-size="16" fill="#000">Edit</text>
          <text x="10" y="120" font-family="Arial" font-size="16" fill="#000">Create</text>
          <text x="10" y="150" font-family="Arial" font-size="16" fill="#000">Delete</text>

          <g>
            <rect x="120" y="40" width="20" height="20" fill="#E6D4FF" stroke="#8F77FF" />
            <rect x="120" y="70" width="20" height="20" fill="#E6D4FF" stroke="#8F77FF" />
            <rect x="120" y="100" width="20" height="20" fill="#E6D4FF" stroke="#8F77FF" />
            <rect x="120" y="130" width="20" height="20" fill="#E6D4FF" stroke="#8F77FF" />

            <rect x="170" y="40" width="20" height="20" fill="#E6D4FF" stroke="#8F77FF" />
            <rect x="170" y="70" width="20" height="20" fill="#E6D4FF" stroke="#8F77FF" />
            <rect x="170" y="100" width="20" height="20" fill="#E6D4FF" stroke="#8F77FF" />
            <rect x="170" y="130" width="20" height="20" fill="#FFF" stroke="#8F77FF" />

            <rect x="220" y="40" width="20" height="20" fill="#E6D4FF" stroke="#8F77FF" />
            <rect x="220" y="70" width="20" height="20" fill="#FFF" stroke="#8F77FF" />
            <rect x="220" y="100" width="20" height="20" fill="#FFF" stroke="#8F77FF" />
            <rect x="220" y="130" width="20" height="20" fill="#FFF" stroke="#8F77FF" />
          </g>

          <polygon points="205,100 220,110 205,120" fill="#FFB900" />
          <text x="160" y="120" font-family="Arial" font-size="12" fill="#000">You</text>
        </g>

        <g transform="translate(400,100)">
          <rect x="0" y="0" width="100" height="100" rx="10" ry="10" fill="#FFF5E5" />
          <text x="50%" y="-20" text-anchor="middle" font-family="Arial" font-size="18" fill="#000">Authorization Microservice</text>
        </g>

        <g transform="translate(600,50)">
          <rect x="0" y="0" width="300" height="200" rx="10" ry="10" fill="#2A2A2A" />
          <text x="50%" y="-20" text-anchor="middle" font-family="Arial" font-size="18" fill="#FFF">Your Code</text>

          <text x="20" y="40" font-family="Courier New" font-size="14" fill="#FFF">@app.post("/create_document")</text>
          <text x="20" y="60" font-family="Courier New" font-size="14" fill="#FFF">async def create_document():</text>
          <text x="40" y="80" font-family="Courier New" font-size="14" fill="#FFF">permitted = await</text>
          <text x="40" y="100" font-family="Courier New" font-size="14" fill="#FFF">permit.check(user, "Create", "Document")</text>
          <text x="40" y="120" font-family="Courier New" font-size="14" fill="#FFF">if permitted:</text>
          <text x="60" y="140" font-family="Courier New" font-size="14" fill="#0F0">// Allow action</text>
          <text x="40" y="160" font-family="Courier New" font-size="14" fill="#FFF">else:</text>
          <text x="60" y="180" font-family="Courier New" font-size="14" fill="#F00">// Deny action</text>
        </g>

        <g transform="translate(950,300)">
          <circle cx="0" cy="0" r="25" fill="#FFE0CF" />
          <text x="0" y="5" text-anchor="middle" font-family="Arial" font-size="16" fill="#000">Admin</text>
        </g>
        <g transform="translate(1000,300)">
          <circle cx="0" cy="0" r="25" fill="#FFE0CF" />
          <text x="0" y="5" text-anchor="middle" font-family="Arial" font-size="16" fill="#000">Editor</text>
        </g>
        <g transform="translate(1050,300)">
          <circle cx="0" cy="0" r="25" fill="#FFE0CF" />
          <text x="0" y="5" text-anchor="middle" font-family="Arial" font-size="16" fill="#000">Viewer</text>
        </g>

        <line x1="350" y1="100" x2="400" y2="150" stroke="#C8C8C8" stroke-width="2" />
        <line x1="500" y1="150" x2="600" y2="150" stroke="#C8C8C8" stroke-width="2" />
        <line x1="700" y1="250" x2="950" y2="300" stroke="#C8C8C8" stroke-width="2" />
        <line x1="700" y1="250" x2="1000" y2="300" stroke="#C8C8C8" stroke-width="2" />
        <line x1="700" y1="250" x2="1050" y2="300" stroke="#C8C8C8" stroke-width="2" />
      </svg> */}
      <svg width="1200" height="600" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="100%" fill="#FDECE4" />

        <g transform="translate(50,50)">
          <rect x="0" y="0" width="350" height="200" rx="10" ry="10" fill="#FFE0CF" />
          <text x="50%" y="-20" text-anchor="middle" font-family="Arial" font-size="18" fill="#000">Permit Policy Editor</text>

          <text x="70" y="30" font-family="Arial" font-size="16" fill="#000">Document</text>
          <text x="120" y="30" font-family="Arial" font-size="16" fill="#000">Admin</text>
          <text x="170" y="30" font-family="Arial" font-size="16" fill="#000">Editor</text>
          <text x="220" y="30" font-family="Arial" font-size="16" fill="#000">Viewer</text>

          <text x="10" y="70" font-family="Arial" font-size="16" fill="#000">View</text>
          <text x="10" y="100" font-family="Arial" font-size="16" fill="#000">Edit</text>
          <text x="10" y="130" font-family="Arial" font-size="16" fill="#000">Create</text>
          <text x="10" y="160" font-family="Arial" font-size="16" fill="#000">Delete</text>

          <g>
            <rect x="120" y="50" width="20" height="20" fill="#D4B4FF" stroke="#8F77FF" />
            <rect x="120" y="80" width="20" height="20" fill="#D4B4FF" stroke="#8F77FF" />
            <rect x="120" y="110" width="20" height="20" fill="#D4B4FF" stroke="#8F77FF" />
            <rect x="120" y="140" width="20" height="20" fill="#D4B4FF" stroke="#8F77FF" />

            <rect x="170" y="50" width="20" height="20" fill="#D4B4FF" stroke="#8F77FF" />
            <rect x="170" y="80" width="20" height="20" fill="#D4B4FF" stroke="#8F77FF" />
            <rect x="170" y="110" width="20" height="20" fill="#D4B4FF" stroke="#8F77FF" />
            <rect x="170" y="140" width="20" height="20" fill="#FFF" stroke="#8F77FF" />

            <rect x="220" y="50" width="20" height="20" fill="#D4B4FF" stroke="#8F77FF" />
            <rect x="220" y="80" width="20" height="20" fill="#FFF" stroke="#8F77FF" />
            <rect x="220" y="110" width="20" height="20" fill="#FFF" stroke="#8F77FF" />
            <rect x="220" y="140" width="20" height="20" fill="#FFF" stroke="#8F77FF" />
          </g>

          <polygon points="210,110 230,120 210,130" fill="#FFB900" />
          <text x="175" y="125" font-family="Arial" font-size="12" fill="#000">You</text>
        </g>

        <g transform="translate(450,100)">
          <rect x="0" y="0" width="100" height="100" rx="10" ry="10" fill="#FFF5E5" />
          <text x="50%" y="-20" text-anchor="middle" font-family="Arial" font-size="18" fill="#000">Authorization Microservice</text>
        </g>

        <g transform="translate(600,50)">
          <rect x="0" y="0" width="300" height="200" rx="10" ry="10" fill="#2A2A2A" />
          <text x="50%" y="-20" text-anchor="middle" font-family="Arial" font-size="18" fill="#FFF">Your Code</text>

          <text x="20" y="40" font-family="Courier New" font-size="14" fill="#FFF">@app.post("/create_document")</text>
          <text x="20" y="60" font-family="Courier New" font-size="14" fill="#FFF">async def create_document():</text>
          <text x="40" y="80" font-family="Courier New" font-size="14" fill="#FFF">permitted = await</text>
          <text x="40" y="100" font-family="Courier New" font-size="14" fill="#FFF">permit.check(user, "Create", "Document")</text>
          <text x="40" y="120" font-family="Courier New" font-size="14" fill="#FFF">if permitted:</text>
          <text x="60" y="140" font-family="Courier New" font-size="14" fill="#0F0">// Allow action</text>
          <text x="40" y="160" font-family="Courier New" font-size="14" fill="#FFF">else:</text>
          <text x="60" y="180" font-family="Courier New" font-size="14" fill="#F00">// Deny action</text>
        </g>

        <g transform="translate(950,300)">
          <circle cx="0" cy="0" r="25" fill="#FFE0CF" />
          <text x="0" y="5" text-anchor="middle" font-family="Arial" font-size="16" fill="#000">Admin</text>
        </g>
        <g transform="translate(1000,300)">
          <circle cx="0" cy="0" r="25" fill="#FFE0CF" />
          <text x="0" y="5" text-anchor="middle" font-family="Arial" font-size="16" fill="#000">Editor</text>
        </g>
        <g transform="translate(1050,300)">
          <circle cx="0" cy="0" r="25" fill="#FFE0CF" />
          <text x="0" y="5" text-anchor="middle" font-family="Arial" font-size="16" fill="#000">Viewer</text>
        </g>

        <line x1="350" y1="100" x2="450" y2="150" stroke="#C8C8C8" stroke-width="2" />
        <line x1="500" y1="150" x2="600" y2="150" stroke="#C8C8C8" stroke-width="2" />
        <line x1="700" y1="250" x2="950" y2="300" stroke="#C8C8C8" stroke-width="2" />
        <line x1="700" y1="250" x2="1000" y2="300" stroke="#C8C8C8" stroke-width="2" />
        <line x1="700" y1="250" x2="1050" y2="300" stroke="#C8C8C8" stroke-width="2" />
      </svg>
    </>
  )
}


function HomePage() {
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="App" style={{
      backgroundColor: 'rgb(46, 37, 56)',
      maxWidth: '100vw',
      // height:'100vh',
      // padding: '20px',
      boxSizing: 'border-box',

      position: 'relative',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',

      color: 'white',
      overflowX: 'hidden',

      minWidth: 1,

    }}>

      <div style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
      }}>
        <MovingDots />
      </div>
      <div style={{
        backgroundColor: 'black',
        width: '100%',
        padding: '40px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 2,
      }}>
        <Logo />
        <CatchPhrase />
        <Button
          active={true}
          style={{ zIndex: 2, border: '3px solid white' }}
          onClick={() => {
            window.scrollTo({
              top: document.documentElement.scrollHeight,
              behavior: 'smooth'
            })


            fetch(email_listener_url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                action_type: 'someone clicked see cyeye.ai in action',
              })
            })

          }}
        >see cyeye.ai in action</Button>
      </div>
      <CodeComparison isMobile={isMobile} />
      <Features isMobile={isMobile} />
      <TestElement />
      <ContactUs isMobile={isMobile} />
    </div>
  );
}


function TermsOfService() {
  return (
    <pre>
      {`Terms of Service for CyEye.ai

Last updated: 24.05.2024

1. Acceptance of Terms
By accessing and using CyEye.ai (the "Website"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree with any part of these Terms, you should not use the Website.

2. Privacy
Your privacy is important to us. Please review our Privacy Policy, which also governs your visit to the Website, to understand our practices.

3. Information Collection and Use
a. When you use the Website, you will be asked to provide personal information such as your name and email address.
b. By providing this information, you grant us the permission to contact you regarding our product and any updates or information related to it.

4. Communication
By providing your contact information, you agree to receive communications from us. These communications may include, but are not limited to, emails about product updates, news, and promotions. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.

5. Changes to Terms
We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

6. Contact Us
If you have any questions about these Terms, please contact us at ereb0s.labratory@gmail.com.`}


    </pre>
  )
}


function PrivacyPolicy() {
  return (
    <pre>
      {`Privacy Policy for CyEye.ai

Effective Date: 24.05.2024

1. Introduction

Welcome to CyEye.ai. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy, or our practices with regards to your personal information, please contact us at ereb0s.labratory@gmail.com

2. Information We Collect

As part of our operations, we collect the following information:

Personal Information: When you visit our website, you may voluntarily provide us with personal information that you expressly consent to use and disclose as described in this privacy policy. This includes:
Name: We collect your first and last name so we can personalize our communications with you.
Email Address: We collect your email address so that we can send you emails regarding our products and any updates or information you might find useful.
3. How We Use Your Information

We use the information we collect for various purposes:

To send you product updates and information about CyEye.ai as per your consent.
To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.
To comply with our legal obligations, resolve any disputes we may have with any of our users, and enforce our agreements with third parties.
4. How We Share Your Information

Your information is not shared with any third parties, except as necessary to fulfill our business obligations and to comply with the law.

5. Security of Your Information

We strive to protect the security of your personal information. While we take reasonable precautions to guard the personal information we collect, no security system is impenetrable.

6. Changes to This Privacy Policy

We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.

7. Contact Us

If you have any questions or comments about this policy, you may email us at ereb0s.labratory@gmail.com`}
    </pre>
  )
}


function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage2 />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  )
}

export default App;
