import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { PopupWidget, InlineWidget } from "react-calendly";
import FeatureCards from './comps/FeatureCards';


import { MovingDots } from "./App";
import Diagram1 from './comps/diagram';

const PURPLE_COLOR = 'rgb(222, 73, 189)'
const YELLOW_COLOR = 'rgb(245, 218, 69)'

const LIGHT_MUTE = 'rgb(99, 85, 115)'

function Logo(props) {

    const isMobile = {props};

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',

            fontSize: isMobile ? '20px' : '40px',
            fontWeight: '500',
        }}>
            <img src="/wyw.svg" style={{
                // backgroundColor: 'red',
                height: isMobile ? '30px' : '50px',
                padding: isMobile ? '2px' : '5px',
                boxSizing: 'border-box',

                position: 'relative',
                bottom: isMobile ? -2 : -4,

            }} />

            <span style={{
                // backgroundColor:'blue'
            }}>cyeye.ai</span>
        </div>
    );
}

function NavBarCategory(props) {
    const [isHover, setIsHover] = useState(false);

    return (
        <div style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',

            maginLeft: '20px',
            marginRight: '20px',

            fontSize: '18px',
            fontWeight: '400',

            color: isHover ? 'white' : 'gray',
            transition: '0.2s all',

            cursor: 'pointer',

        }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {props.text}
            <div style={{
                color: isHover ? 'white' : 'gray',
                marginLeft: '5px',
                transform: `rotate(${isHover ? -90 : 90}deg)`,
                transition: '0.2s all'
            }}>></div>
        </div>
    )
}

function NavBar(props) {

    const { isMobile } = props;

    return (
        <div style={{
            backgroundColor: 'black',
            width: '100%',

            padding: isMobile ? '4px 20px' : '20px 40px',
            boxSizing: 'border-box',

            position: 'sticky',
            top: 0,
            left: 0,

            // borderBottom: 'solid gray 2px',
            zIndex: 10,

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        }}>
            <div style={{
                // backgroundColor: 'red',
                flex: '1 0 0%'
            }}>
                <Logo isMobile={isMobile}/>
            </div>

            {
                !isMobile && (
                    <div style={{
                        // backgroundColor: 'green',
                        flex: '1 0 0%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <NavBarCategory text="Solutions" />
                        <NavBarCategory text="Contact Us" />
                    </div>
                )
            }



            <div style={{
                // backgroundColor: 'blue',
                flex: '1 0 0%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }}>
                <Button2 text={"Reach out"} isMobile={isMobile}/>
            </div>

        </div>
    )
}

function Gradient() {
    return (
        <div style={{
            background: 'linear-gradient(to bottom, black 20%, transparent 100%)',
            // backgroundColor:'red',
            width: '100%',
            height: '500px',

            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 2,
        }} />
    )
}


function Button2(props) {
    const [isHover, setIsHover] = useState(false);

    return (
        <div style={{
            fontSize: '20px',
            fontWeight: '600',
            padding: '10px 30px',

            backgroundColor: PURPLE_COLOR,

            borderRadius: '25px',
            transform: `scale(${isHover && true ? 1.1 : 1})`,

            cursor: 'pointer',
            transition: 'all 0.2s ease-in-out',
            zIndex: 2,

            color: 'white',
        }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={props.onClick}
        // onClick={props.active ? props.onClick : () => null}
        >
            {props.text}
        </div>
    )
}


function Button(props) {
    const [isHover, setIsHover] = useState(false);

    return (
        <div style={{
            fontSize: props.isMobile ? '16px' : '20px',
            fontWeight: '600',
            padding: '10px 30px',

            backdropFilter: 'blur(2px)',
            backgroundColor: `rgba(255,255,255,${isHover ? 0.4 : 0.1})`,

            borderRadius: '25px',
            transform: `scale(${isHover && true ? 1.1 : 1})`,

            cursor: 'pointer',
            transition: 'all 0.2s ease-in-out',
        }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={props.onClick}
        // onClick={props.active ? props.onClick : () => null}
        >
            {props.text}
        </div>
    )
}

function Title(props) {

    const { isMobile } = props;

    const gradient_text = {
        background: `linear-gradient(90deg, ${PURPLE_COLOR}, ${YELLOW_COLOR})`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
    };

    return (
        <div style={{
            // width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            padding: '20px 50px',
            marginTop: '100px',
            zIndex: 3,

            gap: '1.5rem'
        }}>
            <div style={{
                fontSize: isMobile ? '34px' : '64px',
                fontWeight: '600',
                textAlign: 'center',
            }}>
                <div>Deploy LLM Products Safely</div>
                {/* <div>with One Line of Code</div> */}
                <div>
                    with <span style={gradient_text}>One Line of Code</span>
                </div>
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
            }}>
                <Button text="Learn more" />
            </div>
        </div>
    )
}

function Separator() {
    return (
        <div style={{
            background: `linear-gradient(to right, ${PURPLE_COLOR}, ${YELLOW_COLOR})`,

            width: '100%',
            height: '5px',

            zIndex: 3,

            marginBottom: '100px',
            position: 'relative',
        }}>
            <div style={{
                background: `linear-gradient(to right, ${PURPLE_COLOR}, ${YELLOW_COLOR})`,

                width: '100%',
                height: '5px',

                zIndex: 5,

                position: 'absolute',
                top: 0,
                left: 0

            }} />

            <div style={{
                background: 'linear-gradient(to bottom, rgb(12, 4, 18) 20%, transparent 100%)',
                // backgroundColor:'red',
                width: '100%',
                height: '300px',

                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 3,
            }} />
        </div>
    )
}


function GotQuestions(props) {
    const {isMobile} = props;
    return (
        <div style={{

            backgroundColor: 'rgb(118, 103, 135)',
            width: isMobile ? '80%' : '900px',

            padding: isMobile ? '24px 16px' : '48px 64px',
            boxSizing:'border-box',

            margin: '20px 0px',
            borderRadius: '16px',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',

            zIndex: 2,

        }}>
            <div style={{
                fontSize: isMobile ? '16px' : '26px',
                fontWeight: '600',
            }}>
                <div>Got Questions?</div>
                <div>We're happy to talk.</div>
            </div>

            <Button text="Contact us" onClick={() => props.setShowPopUp(true)} isMobile={isMobile}/>
        </div>
    )

}

function Testimonial(props) {
    const {isMobile} = props;


    return (
        <div style={{
            width: isMobile ? '360px' :'410px',
            height: isMobile ? '300px' :'270px',

            borderRadius: '5px',

            backdropFilter: 'blur(2px)',
            backgroundColor: `rgba(255,255,255,0.5)`,

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',

            gap: '10px',

            padding: '15px',
            boxSizing: 'border-box',
            color: 'rgb(36, 29, 43)'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
            }}>
                <img src="/icons/profile.svg" style={{ height: '50px', }} />
                <div>
                    <div style={{ fontWeight: '600' }}>{props.name}</div>
                    <div>{props.title}</div>
                </div>
            </div>

            <div style={{ fontSize: '18px' }}>
                {props.children}
            </div>
        </div>
    )
}

function Testimonials(props) {

    const {isMobile} = props;

    const gradient_text = {
        background: `linear-gradient(90deg, ${PURPLE_COLOR}, ${YELLOW_COLOR})`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
    };

    return (
        <div style={{
            width: '100%',
            padding: isMobile ? '30px' : '144px',
            boxSizing: 'border-box',

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '50px',
            zIndex: 2,
        }}>
            <div style={{
                fontSize: isMobile ? '26px' : '36px',
                fontWeight: '600',
                textAlign: 'center',
            }}>
                <div>Hear What our Customers</div>
                <div>are <span style={gradient_text}>Saying About cyeye.ai</span></div>
            </div>
            <div style={{


                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',

                gap: '20px',
                zIndex: 2,
            }}>
                <Testimonial
                    name={"John. S"}
                    title={"CEO"}
                    isMobile={isMobile}
                >
                    The bring-up was intuitive and momentary. Within literal minutes I had a fully functioning dashboard showing all the LLM activity in my app.
                    Withing a week I saw a completely different image of how users were using my app than what I thought.
                    I realized that I had no idea what was truly going on between my customers and the LLM.
                </Testimonial>
                <Testimonial
                    name={"Jamal S."}
                    title={"VP R&D"}
                    isMobile={isMobile}
                >
                    Integrating cyeye.ai was as easy as installing a new module. We've gained a lot of insights thanks to the platform
                    about our customers. The firewall is also one of the best features, I honestly didn't expect it to be relevant at all, but
                    it blocked and reported a lot of weird acitivty I never knew was happening through my app.
                </Testimonial>
                <Testimonial
                    name={"Elena P."}
                    title={"CEO"}
                    isMobile={isMobile}
                >
                    cyeye.ai reduced our LLM costs by roughly 20%. After a month of running with it we realized a lot of conversations
                    users are having with the AI are redundant and happen due to poor context that given to the LLM.
                </Testimonial>
                <Testimonial
                    name={"Valery O."}
                    title={"CISO"}
                    isMobile={isMobile}
                >
                    I love that cyeye.ai does the active probing to make sure the LLM providers we use don't train on our data.
                    As a CISO such a scenario would be an absolute disaster, so the weekly reports cyeye.ai generates about
                    probing LLMs for our sensitive data keeps me at ease.
                </Testimonial>
                <Testimonial
                    name={"Richard P."}
                    title={"CISO"}
                    isMobile={isMobile}
                >
                    It's been a long time since I've been this happy to tell my CEO about a new security tool.
                    The face that we've integrated a LLM into our product was bothering me for a long time now, but I never
                    knew exactly what to do about it. What more measures can I employ to make sure we're doing this the safest way possible.
                    cyeye definitely scratched that itch
                </Testimonial>
            </div>

            <Button2 active={true} text="Schedule a meeting" onClick={() => props.setShowPopUp(true)} />
        </div>
    )
}

function Tail() {
    const navigate = useNavigate();

    return (
        <div style={{
            backgroundColor: 'black',
            width: '100%',
            padding: '64px 48px',
            boxSizing: 'border-box',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',

            position: 'relative',
            zIndex: 2,
        }}>
            <div style={{
                backgroundColor: 'white',
                width: '95%',
                height: '1px',

                position: 'absolute',
                top: '15%',
                left: '2.5%',
            }} />
            <div style={{
                // backgroundColor:'red',
                flex: '1 0 0%',

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                // alignItems:'stretch'
            }}>
                <div style={{
                    flex: '1 0 0%',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    cyeye.ai
                </div>
                <div style={{
                    flex: '1 0 0%',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                }}>
                    <div style={{ fontWeight: '600', marginBottom: '5px' }}>PRODUCT</div>
                    <div style={{ fontWeight: '200', cursor: 'pointer' }}
                        onClick={() => navigate('/terms-of-service')}>Terms & Conditions</div>
                    <div style={{ fontWeight: '200', cursor: 'pointer' }}
                        onClick={() => navigate('/privacy-policy')}>Privacy Policy</div>
                </div>

                <div style={{
                    flex: '1 0 0%',
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                </div>

                <div style={{
                    position: 'absolute',
                    right: 60,
                    bottom: 20,
                }}>
                    @ 2024 cyeye.ai inc
                </div>

            </div>
        </div>
    )
}

function ContactUsPopup(props) {
    const {isMobile} = props;

    return (
        <div style={{
            backgroundColor: 'rgba(0,0,0,0.9)',
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            top: 0,
            left: 0,

            zIndex: 10,

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            overflow:'hidden',

            padding: isMobile ? '20px' : 'none',
            boxSizing:'border-box',

        }}
            onClick={props.closePopup}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: isMobile ? '100%' : '1000px',
                height: isMobile ? '100%' : '800px',
            }}>
                <div style={{
                    fontSize: '20px',
                    color: 'white',
                    fontWeight: '600',

                    alignSelf: 'start',
                    cursor: 'pointer',
                }}
                    onClick={props.closePopup}>
                    X
                </div>
                <InlineWidget styles={{
                    width: '100%',
                    height: '100%',
                }} url="https://calendly.com/ereb0s-labratory/cyeye-ai-intro-demo-meeting?primary_color=f5da45" />
            </div>
        </div>
    )

}

function HomePage2() {
    const [showPopUp, setShowPopUp] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    // Effect to update the state on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            overflowY: 'auto',
            color: 'white',
        }}>
            <div className="App" style={{
                backgroundColor: 'rgb(36, 29, 43)',
                maxWidth: '100vw',
                // height: '100vh',

                position: 'relative',

                // height:'100vh',
                // padding: '20px',
                boxSizing: 'border-box',

                position: 'relative',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',


                minWidth: 1,
                minHeight: 1,

                overflowX: 'hidden',
                overflowY: 'hidden',
            }}>
                <NavBar isMobile={isMobile} />
                <Gradient isMobile={isMobile} />
                <div style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,

                    // overflow:'hidden',
                }}>
                    <MovingDots />
                </div>

                <Title setShowPopUp={setShowPopUp} isMobile={isMobile} />
                <Diagram1 isMobile={isMobile} />
                <Separator isMobile={isMobile} />
                <FeatureCards setShowPopUp={setShowPopUp} isMobile={isMobile} />
                <GotQuestions setShowPopUp={setShowPopUp} isMobile={isMobile} />
                {/* <Testimonials setShowPopUp={setShowPopUp} isMobile={isMobile} /> */}
                <Tail />

            </div>

            {
                showPopUp && (
                    <ContactUsPopup isMobile={isMobile} closePopup={() => setShowPopUp(false)} />
                )
            }

        </div>
    )
}

export default HomePage2;