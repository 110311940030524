import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const PURPLE_COLOR = 'rgb(222, 73, 189)'
const YELLOW_COLOR = 'rgb(245, 218, 69)'


function ChatDiv() {

    function createContent() {
        return Array.from({ length: Math.floor(Math.random() * (50 - 10 + 1)) + 10 }, () => Math.random() < 0.5 ? 0 : Math.floor(Math.random() * 6) + 1)
    }

    const [msgs, setMsgs] = useState([]);

    // const msgs = [
    //     { type: 'user', content: createContent() },
    // ]

    useEffect(() => {
        let isMounted = true; // Add a flag to track if the component is mounted

        function addNewMessage() {
            if (!isMounted) return; // Exit if the component is unmounted

            setMsgs((prevMsgs) => {
                const newMsgs = [...prevMsgs, { id: prevMsgs.length == 0 ? 0 : prevMsgs[prevMsgs.length - 1].id + 1, type: prevMsgs.length == 0 ? 'user' : prevMsgs[prevMsgs.length - 1].type == 'ai' ? 'user' : 'ai', content: createContent() }];
                // Keep only the last 10 messages 
                return newMsgs.length > 10 ? newMsgs.slice(-10) : newMsgs;
            });
            setTimeout(addNewMessage, 2000);
        }

        addNewMessage();

        // Cleanup function to set isMounted to false
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div style={{
            height: '100%',
            overflow: 'hidden',

            // backgroundColor:'green',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',

            // padding:'10px',
            // boxSizing:'border-box',

            gap: '10px',

            width: '100%',
            // height:'100px',
        }}>
            <style>
                {`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`}
            </style>
            <AnimatePresence>
                {
                    msgs.map((item, index) => (
                        <motion.div key={item.id}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5 }}
                            layout
                            style={{
                                backgroundColor: item.type == 'ai' ? 'rgb(162, 140, 209)' : 'rgb(140, 144, 209)',
                                maxWidth: '60%',
                                // height: '20px',

                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexWrap: 'wrap',

                                borderRadius: '10px',

                                padding: '5px',


                                alignSelf: item.type == 'ai' ? 'end' : 'start',
                                gap: '5px',

                                // animation: '1s fadeIn'

                            }}>
                            {
                                item.content.map((item, index) => (
                                    <div key={index} style={{
                                        backgroundColor: 'white',
                                        width: `${item * 10}px`,
                                        height: '7px',
                                        borderRadius: '5px',
                                    }}>

                                    </div>
                                ))
                            }
                        </motion.div>
                    ))
                }

            </AnimatePresence>
        </div>
    )
}

function YourApp() {


    return (
        <div style={{
            backgroundColor: 'rgb(209, 193, 227)',
            height: '460px',
            width: '250px',

            position: 'absolute',
            top: '50px',
            left: '20px',

            borderRadius: '20px',
            border: `solid 5px ${"rgb(118, 103, 135)"}`,

            boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',

            overflow: 'hidden',
            zIndex: 3,
        }}>
            <div style={{
                backgroundColor: 'rgb(118, 103,135)',
                width: '100%',
                height: '30px',
                borderBottom: `solid 1px rgb(118, 103, 135)`,

                position: 'absolute',
                top: -2,
                left: 0,

                fontSize: '16px',
                fontWeight: '600',

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'end',

                padding: '3px',
                boxSizing: 'border-box',
            }}>

                <div style={{
                    marginLeft: '5px',
                }}></div>

                <div style={{
                    alignSelf: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                }}>
                    <div style={{
                        backgroundColor: 'rgb(100,200,100)',
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                    }} />

                    <div style={{
                        backgroundColor: 'rgb(200,200,100)',
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                    }} />

                    <div style={{
                        backgroundColor: 'rgb(200,100,100)',
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                    }} />
                </div>
            </div>


            <div style={{
                // backgroundColor: 'red',
                width: '90%',
                height: '370px',
                position: 'absolute',
                left: 13,
                top: 30,

                // overflow:'hidden',
            }}>
                {/* test */}
                <ChatDiv />
            </div>


            <div style={{
                backgroundColor: "rgb(180, 161, 201)",

                position: 'absolute',
                bottom: 0,
                right: 0,

                width: '100%',
                height: '50px',

                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch',
                gap: '10px',

                padding: '10px',
                boxSizing: 'border-box',
            }}>
                <div style={{
                    backgroundColor: 'white',
                    flex: '8 0 0%',
                    borderRadius: '15px',
                }}>

                </div>
                <div style={{
                    backgroundColor: 'black',
                    flex: '1.5 0 0%',
                    borderRadius: '100%',
                }}>

                </div>
            </div>
        </div>
    )
}


function CodeArea(props) {

    const bottom_lines_with_eyeai = [
        "Active monitoring of all activity",
        "Filtering and alerts based on the data users request and see",
        "Smart AI-based testing to pre-detect potential data leakage and security vulnerabilites"
    ];

    const bottom_lines_without_eyeai = [
        "No monitoring",
        "No filtering",
        "No testing"
    ];

    const line_of_code_style = { margin: '0px', marginBottom: '5px', padding: '0px' }
    const error_code_style = { color: 'rgb(255,100,100)', backgroundColor: 'rgba(255, 50, 50, 0.5)' }
    const eyeai_code_style = { color: 'rgb(100,255,100)', backgroundColor: 'rgba(50, 255, 50, 0.5)' }

    return (
        <div style={{
            position: 'relative',
            top: '15px',
            left: '65px',

            // height:'100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',

            zIndex: 2,
            // overflow:'hidden',
        }}>
            <div style={{
                backgroundColor: 'rgb(15, 18, 15)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch',

                minWidth: 1,
                minHeight: 1,

                margin: '10px',
                fontFamily: 'Roboto Mono',
                fontSize: '16px',
            }}>
                <div style={{
                    backgroundColor: '#2F2F2F',
                    width: '40px',

                    textAlign: 'end',
                    padding: '10px',
                    boxSizing: 'border-box',
                }}>
                    {Array.from({ length: 10 }, (_, index) => (
                        <pre key={index} style={line_of_code_style}>{index}</pre>
                    ))}

                </div>
                <div style={{

                    padding: '10px',
                    boxSizing: 'border-box',

                    width: (props.isMobile ? '300px' : '500px'),

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'start',

                    overflowX: 'auto'
                }}>
                    {
                        props.eyeai ?
                            <>
                                <pre style={line_of_code_style}><span style={{ color: '#2e95d3' }}>from </span><span style={eyeai_code_style}>cyeyeai</span><span style={{ color: '#2e95d3' }}> import</span> openai</pre>

                                <pre style={line_of_code_style}>{" "}</pre>
                                <pre style={line_of_code_style}>response = <span style={{ backgroundColor: 'rgba(0,255,0, 0.3)' }}>openai.Completion.create(</span></pre>
                                <pre style={line_of_code_style}>{'\t'}<span style={{ backgroundColor: 'rgba(0,255,0, 0.3)' }}>engine=<span style={{ color: '#00a67d' }}>"gpt-4-turbo"</span>,</span></pre>
                                <pre style={{ ...line_of_code_style, position: 'relative' }}>{`\t`}<span style={{ backgroundColor: 'rgba(0,255,0, 0.3)' }}>prompt=precious_company_data,</span><div style={{
                                    // backgroundColor: 'red',
                                    position: 'absolute',
                                    height: '100%',
                                    // width:'25px',
                                    top: 2,
                                    right: '-25px',
                                }}>
                                    <img src="/wyw.svg" style={{ width: '100%', height: '100%' }} />
                                </div></pre>
                                <pre style={{ ...line_of_code_style, backgroundColor: 'rgba(0,255,0, 0.3)' }}>{`)`}</pre>
                            </>
                            :
                            <>
                                <pre style={line_of_code_style}><span style={{ color: '#2e95d3' }}>import</span> openai</pre>

                                <pre style={line_of_code_style}>{" "}</pre>
                                <pre style={line_of_code_style}>response = openai.Completion.create(</pre>
                                <pre style={line_of_code_style}>{`\tengine=`}<span style={{ color: '#00a67d' }}>"gpt-4-turbo"</span>,</pre>
                                <pre style={line_of_code_style}>{`\tprompt=`}<span style={error_code_style}>precious_company_data</span>,</pre>
                                <pre style={line_of_code_style}>{`)`}</pre>
                            </>
                    }


                </div>
            </div>


        </div>
    )
}



function YourBackend() {


    return (
        <div style={{
            backgroundColor: 'white',
            height: '200px',
            width: '410px',

            position: 'absolute',
            top: '50px',
            left: '50%',
            transform: 'translateX(-50%)',

            borderRadius: '20px',
            border: `solid 5px ${"rgb(118, 103, 135)"}`,

            boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',

            overflow: 'hidden',
            zIndex: 3,
        }}>

            <div style={{
                backgroundColor: 'rgb(118, 103,135)',
                width: '100%',
                height: '30px',
                borderBottom: `solid 1px rgb(118, 103, 135)`,

                position: 'absolute',
                top: -2,
                left: 0,

                fontSize: '16px',
                fontWeight: '600',

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'end',

                padding: '3px',
                boxSizing: 'border-box',
            }}>
                <div style={{
                    marginLeft: '5px',
                }}></div>

                <div style={{
                    alignSelf: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                }}>
                    <div style={{
                        backgroundColor: 'rgb(100,200,100)',
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                    }} />

                    <div style={{
                        backgroundColor: 'rgb(200,200,100)',
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                    }} />

                    <div style={{
                        backgroundColor: 'rgb(200,100,100)',
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                    }} />
                </div>
            </div>

            <CodeArea eyeai={true} />
        </div>
    )
}


function LLMApi({ isMobile }) {
    return (
        <div style={{
            // backgroundColor: 'white',
            background: 'linear-gradient(to bottom, rgb(72, 61, 84), rgb(32, 26, 38))',
            height: '150px',
            width: '150px',

            position: 'absolute',
            top: isMobile ? '500px' : '50px',
            right: isMobile ? '520px' : '50px',

            borderRadius: '20px',
            border: `solid 5px ${"rgb(118, 103, 135)"}`,

            boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            overflow: 'hidden',
            zIndex: 3,

            transform: isMobile ? 'scale(1.5)' : 'none',
        }}>
            <div style={{
                width: '70px',
                height: '70px',
                border: '5px solid transparent',
                borderRadius: '100%',
                background: 'linear-gradient(to bottom, rgb(32, 26, 38), rgb(72, 61, 84)) border-box',
                WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
                WebkitMaskComposite: 'xor',
                maskComposite: 'exclude',
                zIndex: 5,
            }} />

            <div style={{
                position: 'absolute',
                backgroundColor: 'rgb(32, 26, 38)',
                width: '70px',
                height: '70px',
                border: '5px solid transparent',
                borderRadius: '100%',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',

                fontSize: '25px',
                fontWeight: '600',
                color: 'rgb(118, 103, 135)'
            }}>
                AI
            </div>
        </div>
    )
}

function CyEyeAi({ isMobile }) {
    return (
        <div style={{
            // backgroundColor: 'red',
            background: `linear-gradient(to bottom, ${PURPLE_COLOR}, ${YELLOW_COLOR})`,

            width: '70px',
            height: '70px',

            position: 'absolute',
            top: isMobile ? '320px' : '100px',
            right: isMobile ? '565px' : '260px',
            zIndex: 11,

            borderRadius: '10px',
            // boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            transform: isMobile ? 'scale(1.2)' : 'none',
        }}>

            <div style={{
                backgroundColor: 'black',

                width: '70%',
                height: '70%',

                borderRadius: '100%',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img src="wyw.svg" style={{
                    height: '40px'
                }} />
            </div>
        </div>
    )
}

function DashboardPoint(props) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',

            fontSize: '25px',
            gap: '10px',
        }}>
            <img src="/icons/asterisk.svg" />
            <div>{props.text}</div>
        </div>
    )
}


function DashboardUiNavBar() {
    return (
        <div style={{
            height: '60px',
            // padding:'34px',
            // boxSizing:'border-box',

            // backgroundColor:'rgb(118, 103, 135)',
            backgroundColor: 'black',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'stretch'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '15px',

                paddingLeft: '10px',
                boxSizing: 'border-box',
            }}>
                <img src="/wyw.svg" style={{
                    height: '60%',
                    marginRight: '10px',
                    transform: 'translateY(2px)'
                }} />

                <div style={{
                    fontSize: '20px',
                    fontWeight: '500',
                    opacity: 0.8
                }}>your organization</div>

                <div style={{ opacity: 0.5 }}>></div>

                <div style={{
                    fontSize: '20px',
                    fontWeight: '500',
                    opacity: 0.8
                }}>Endpoints</div>

                <div style={{ opacity: 0.5 }}>></div>

                <div style={{
                    fontSize: '20px',
                    fontWeight: '500',
                }}>Chat #1</div>

            </div>
        </div>
    )
}

function DashboardUiEndPointCategories() {

    const categories_data = [
        { label: 'Overview', im: 'info.svg', active: true },
        { label: 'Analysis', im: 'tree.svg', 'active': false },
        { label: 'Budget Analysis', im: 'dollar.svg', 'active': false },
        { label: 'Security', im: 'warning.svg', 'active': false },
        { label: 'Semantic Firewall', im: 'query.svg', 'active': false },

    ]

    return (
        <div style={{
            width: '100px',
            backgroundColor: 'white',
            borderRight: 'solid 0.8px rgb(218, 222, 227)',

            paddingTop: '5px',
            gap: '5px',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch'
            // padding
        }}>
            {
                categories_data.map((item, index) => (
                    <div key={index}
                        style={{
                            color: item.active ? 'rgb(183, 165, 204)' : 'rgb(43, 48, 56)',
                            fontSize: '16px',
                            padding: '15px 5px',
                            boxSizing: 'border-box',

                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',

                            fontSize: '16px',
                            fontWeight: '600',

                            textAlign: 'center'
                        }}
                    >
                        <div style={{
                            backgroundColor: item.active ? '#d8c2f0' : 'transparent',
                            borderRadius: '20px',

                            width: '100%',
                            padding: '0px 25px',
                            boxSizing: 'border-box',
                        }}>
                            <img src={`/dashboard_icons/${item.im}`} style={{

                                width: '100%',
                            }} />
                        </div>
                        {item.label}
                    </div>
                ))
            }
        </div>
    )
}

function DashboardScreen() {

    const data = [
        { date: '2023-06-01', activity: 30 },
        { date: '2023-06-02', activity: 50 },
        { date: '2023-06-03', activity: 45 },
        { date: '2023-06-04', activity: 60 },
        { date: '2023-06-05', activity: 70 },
        // Add more data points here
    ];

    return (
        <div style={{
            // backgroundColor:'red',
            flex: '1 0 0%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',


        }}>

            <div style={{

                padding: '28px',
                boxSizing: 'border-box',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'start',

                gap: '20px',

                borderBottom: '1px solid rgb(210, 210, 210)',
            }}>

                <div style={{
                    fontSize: '36px',
                    fontWeight: '500',
                    marginBottom: '20px',
                }}>
                    Assistance_chat_endpoint
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    fontSize: '20px',

                    gap: '10px',
                }}>
                    <div style={{ color: 'rgb(170, 170, 170)' }}>Status</div>
                    <div
                        style={{
                            fontSize: '16px',
                            backgroundColor: 'rgb(209, 237, 206)',
                            padding: '2px 10px',
                            borderRadius: '20px',
                        }}
                    >Active</div>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    fontSize: '20px',

                    gap: '10px',
                }}>
                    <div style={{ color: 'rgb(170, 170, 170)' }}>Platform</div>
                    <div>
                        <img style={{ height: '20px', marginRight: '10px', }} src="/llms/chatgpt.png" />
                        Chatgpt - Chat Completion
                    </div>
                </div>



                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    fontSize: '20px',

                    gap: '10px',
                }}>
                    <div style={{ color: 'rgb(170, 170, 170)' }}>Last updated</div>
                    <div
                    >06/08/2024, 11:51 PM</div>
                </div>


                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    fontSize: '20px',

                    gap: '10px',
                }}>
                    <div style={{ color: 'rgb(170, 170, 170)' }}>Total tokens</div>
                    <div
                    >45.6K</div>
                </div>



                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    fontSize: '20px',

                    gap: '10px',
                }}>
                    <div style={{ color: 'rgb(170, 170, 170)' }}>Total budget</div>
                    <div
                    >552$</div>
                </div>


            </div>

            <div style={{

                padding: '28px',
                boxSizing: 'border-box',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'start',

                gap: '20px',

                // borderBottom: '1px solid rgb(210, 210, 210)',
            }}>
                <div style={{
                    fontSize: '24px',
                    fontWeight: '500',
                    marginBottom: '20px',
                }}>
                    Activity
                </div>

                <ResponsiveContainer width="100%" height={200}>
                    <LineChart
                        data={data}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="activity" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                </ResponsiveContainer>
            </div>

        </div>
    )
}

function DashboardUI({ isMobile }) {
    return (
        <div style={{
            backgroundColor: 'white',
            width: '1280px',
            height: '720px',

            transform: `translateX(-50%) translateY(-50%) scale(${isMobile ? 0.6 : 0.45})`,

            position: 'absolute',
            top: isMobile ? '1100px' : '470px',
            left: isMobile ? '400px' : '860px',
            // top: '50%',
            // left: '50%',

            zIndex: 20,
            boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',

            fontFamily: 'Roboto',
            overflow:'hidden',
        }}>
            <DashboardUiNavBar />
            <div style={{
                // backgroundColor:'red',
                flex: '1 0 0%',

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                color: 'rgb(54, 58, 61)',
            }}>
                <DashboardUiEndPointCategories />
                <DashboardScreen />
            </div>
        </div>
    )
}

function Dashboard({ isMobile }) {

    return (
        <div style={{
            // backgroundColor: 'rgb(209, 193, 227)',
            backgroundColor: 'black',
            height: '230px',
            width: '600px',

            position: 'absolute',
            top: isMobile ? '900px' : '290px',
            left: isMobile ? '100px' : '540px',
            transform: isMobile ? 'scale(1.3)' : 'none',



            borderRadius: '20px',
            // border: `solid 5px ${"rgb(118, 103, 135)"}`,
            border: `solid 5px rgb(118, 103, 135)`,

            boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: '5px',

            overflow: 'hidden',
            zIndex: 3,

            // padding: '34px 60px',
            // boxSizing: 'border-box',
        }}>

            <div style={{
                backgroundColor: 'black',
                width: '100%',
                height: '30px',
                // borderBottom: `solid 1px rgb(118, 103, 135)`,

                borderBottom: `solid 5px rgb(118, 103, 135)`,

                position: 'absolute',
                top: -2,
                left: 0,

                fontSize: '16px',
                fontWeight: '600',

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'end',

                padding: '5px',
                boxSizing: 'border-box',
            }}>
                <div style={{
                    // backgroundColor:'red',
                    marginLeft: '5px',
                    height: '100%',

                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    gap: '5px',
                }}>
                    <img src="wyw.svg" />
                    cyeye.ai
                </div>

                <div style={{
                    alignSelf: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                }}>
                    <div style={{
                        backgroundColor: 'rgb(100,200,100)',
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                    }} />

                    <div style={{
                        backgroundColor: 'rgb(200,200,100)',
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                    }} />

                    <div style={{
                        backgroundColor: 'rgb(200,100,100)',
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                    }} />
                </div>
            </div>

            {/* <DashboardUI/> */}
            {/* <DashboardPoint text="Semantic Analysis of All LLM Activity" />
            <DashboardPoint text="Alerts on Suspicous Activity" />
            <DashboardPoint text="Semantic Firewall to Block Unwated Activity" />
            <DashboardPoint text="LLM Budget Optimization Suggestions" /> */}

        </div>
    )
}

function Connector(props) {
    return (
        <div style={{
            backgroundColor: 'rgba(118, 103, 135, 0.5)',
            backdropFilter: 'blur(2px)',
            // backgroundColor:'red',

            height: '50px',
            width: '200px',


            position: 'absolute',
            top: '110px',
            left: '250px',
            zIndex: 2,

            overflow: 'hidden',

            ...props.style
        }}>

            {
                Array(9).fill(0).map((item, index) => (
                    <div key={index} style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        width: '8px',
                        height: '8px',
                        borderRadius: '100%',

                        top: 'calc(50% - 4px)',
                        right: `${index * 50}px`,

                        animation: 'moveDots 1s linear infinite'

                    }} />
                ))
            }
            <style>
                {`
            @keyframes moveDots {
                0% {
                    transform: translateX(0%);
                }
                100% {
                    transform: translateX(50px);
                }
            }
            `}
            </style>
        </div>
    )
}


function Label(props) {
    return (
        <div style={{
            backgroundColor: 'rgb(209, 193, 227)',

            position: 'absolute',
            color: 'rgb(36, 29, 43)',

            fontSize: '16px',
            fontWeight: '600',

            padding: '5px 15px',
            borderRadius: '20px',

            border: 'solid 3px rgb(118, 103, 135)',

            ...props.style,
        }}>
            {props.text}
        </div>
    )
}
function CyEyeDiagramConnector() {
    return (
        <>
            <div style={{
                backgroundColor: 'rgba(118, 103, 135, 0.5)',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 3,
                backdropFilter: 'blur(10px)',
                WebkitMask: 'url(#maskRect1)',
                mask: 'url(#maskRect1)',
            }}>
            </div>

            <svg style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 3
            }}>
                <defs>
                    <mask id="maskRect1">
                        <path id="animatedPath" d="M 560 350 Q 300 320, 370 1000" stroke="white" strokeWidth="55" fill="none" />
                    </mask>
                </defs>

                {Array(9).fill(0).map((_, index) => (
                    <circle key={index} r="4" fill="white">
                        <animateMotion repeatCount="indefinite" dur="9s" begin={`${index * 1}s`}>
                            <mpath href="#animatedPath" />
                        </animateMotion>
                    </circle>
                ))}
            </svg>
        </>
    );
}

function Diagram1(props) {

    const { isMobile } = props;

    return (
        <div style={{
            // backgroundColor: 'red',
            width: '1200px',
            height: isMobile ? '700px' : '700px',
            marginTop: isMobile ? '0px' : '50px',
            marginBottm: isMobile ? '0px' : '50px',

            zIndex: 2,
            position: 'relative',
        }}>
            <div style={{
                width: '1200px',
                height: isMobile ? '900px' : '700px',
                position: 'relative',
                transform: isMobile ? 'scale(0.45) translateY(-50%) translateX(190px)' : 'none',
            }}>
                <YourApp isMobile={isMobile} />
                <Connector isMobile={isMobile} />
                <YourBackend isMobile={isMobile} />
                <LLMApi isMobile={isMobile} />
                <CyEyeAi isMobile={isMobile} />

                {
                    isMobile ? (
                        <Connector isMobile={isMobile} style={{
                            left: '500px',
                            top: '350px',
                            transform: 'rotate(90deg)',
                        }} />

                    ) : (

                        <Connector isMobile={isMobile} style={{
                            left: '800px',
                        }} />
                    )
                }

                {isMobile ?
                    (
                        <CyEyeDiagramConnector />
                    )
                    :
                    (
                        <Connector isMobile={isMobile} style={{
                            left: '805px',
                            top: '220px',
                            transform: 'rotate(90deg)',
                        }} />
                    )}




                {/* <Dashboard isMobile={isMobile} /> */}
                <DashboardUI isMobile={isMobile} />

                <Label text="Your App" style={{ top: 0, left: 100 }} />
                <Label text="Your Backend" style={{ top: 0, left: 535 }} />
                <Label text="LLM API"
                    style={{
                        top: isMobile ? 730 : 0,
                        right: isMobile ? 555 : 85
                    }} />
            </div>
        </div>
    )
}


export default Diagram1;